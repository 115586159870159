export const BATCHES_COPY: any = {
    progress: {
        rendering: 'rendering',
        rendered: 'rendered',
        queued: 'queued',
        failed: 'failed'
    }
};

export const settings = {
    title: 'Settings',
    confirmClose:
        'Your Project has unsaved changes, are you sure you want to close the settings modal?',
    confirmLeave: 'Leave Site? Changes you made may not be saved.',
    confirmComp:
        'This composition has unsaved changes, are you sure you want to close? All unsaved changes will be lost.',
    confirmTemplate:
        'This template has unsaved changes, are you sure you want to close? All unsaved changes will be lost.'
};

export const sections = {
    variables: 'Variables',
    assets: 'Assets',
    config: 'Configuration',
    variablesTooltip: 'Project Variables (V)'
};

export const redux = {
    deleteStorySuccess: 'Project deleted successfully',
    saveStorySuccess: 'Project saved successfully.',
    attemptPublishWithError: `Project can't be published due to errors`,
    createStorySuccess: 'Project created successfully.',
    copyingStory: `Saving Story As '[storyName]'`,
    assetCachedCleared: `Variable Asset Cache Cleared`
};

export const header = {
    tooltipCancel: 'Cancel Publishing',
    tooltipSave: 'Save Changes (cmd+S)',
    tooltipSaveAs: 'Save As',
    tooltipPublish: 'Publish',
    tooltipCancelNewPublish: 'Cancel',
    tooltipCancelPublish: 'Cancel Publish',
    tooltipPreview: 'Render Preview',
    tooltipErrors: 'Review Project For Errors',
    settings: 'Project Settings',
    tooltipComp: 'New Composition',
    working: 'Using Working Copy',
    publishing: 'Publishing...',
    newVideo: 'New Video Project',
    newComposition: 'New Video Project',
    newImage: 'New Image Project',
    import: 'Import Project',
    delete: 'Delete Project',
    cacheClear: 'Clear Variable Asset Cache',
    export: 'Export Project',
    duplicate: 'Duplicate Project',
    exportError: 'Error exporting project',
    confirmDelete: 'Are you sure you want to delete "[storyName]"? This action cannot be undone.',
    confirmClearCache: 'This will delete all variable assets currently cached on this project.',
    statusError: 'Error getting project status',
    uploadsError:
        'Please create a unique uploads bucket for this project. You cannot publish a project with the default dev bucket.',
    resourcesError:
        'Please create a unique rendered bucket for this project. You cannot publish a project with the default dev bucket.',
    baseError:
        'Cannot publish a project without a base video. Please upload a base video to continue.',
    publishJobError: 'Error creating publish project job',
    publishPollError: 'Error polling publish job status',
    messagePlaceholder: 'Publish Message',
    projectOverwritePrompt:
        'This project already exists. Would you like to overwrite it? Warning, this action cannot be undone.',
    importError: 'Error importing project',
    live: 'Live',
    noErrors: 'No Errors Found',
    cancelPublish: 'Publish Cancelled',
    cancelPublishError: 'Error Cancelling Publish',
    batchesError: 'Batches not found',
    btnPublish: 'Publish',
    btnPreview: 'Render',
    checkUnsaved: 'You have unsaved changes, would you like to save and continue?',
    btnNewProject: 'New Project',
    btnSave: 'Save',
    btnSaveAs: 'Save Project As',
    noStoryDefault: 'projects',
    noStoriesFound: 'No projects were found',
    btnUpload: 'Upload',
    btnNewComp: 'New Comp',
    btnNewHTML: 'New HTML',
    unpublished: 'Working copy is unpublished'
};

export const assets = {
    expandPanel: 'Expand/Shrink Panel',
    showTags: 'Show/Hide Tags',
    showVariables: 'Show/Hide Variable Assets',
    upload: 'Upload',
    noData: 'No assets were found, drag and drop files to start.',
    dropAsset: 'Drop file(s) here.',
    noDataFiltered: 'No assets were found matching this criteria.',
    tabs: {
        select: 'Select',
        type: 'Type',
        name: 'Name',
        tags: 'Tags',
        lastModified: 'Last Modified',
        date: 'Date Created',
        lastModifiedBy: 'Last Modified By',
        duration: 'Duration',
        rate: 'FPS',
        height: 'Height',
        width: 'Width'
    },
    newTemplate: 'Template',
    newComposition: 'New Composition',
    tooltipAssetProcessing: 'Asset(s) Processing',
    tooltipDownload: 'Download Selected Asset(s)',
    tooltipDelete: 'Delete Selected Asset(s)',
    tooltipRemoveTags: 'Remove All Tags from Selected Asset(s)',
    tooltipDecouple: 'Decouple Selected Asset(s)',
    tooltipRefresh: 'Refresh Asset List',
    tooltipUpload: 'Upload Asset',
    tooltipDuplicate: 'Duplicate Asset',
    tooltipContextMenu: 'Assets context menu',
    tooltipViewUploads: 'Uploads',
    tooltipNewAsset: 'Create New',
    tooltipLinkOut: 'Go to Standalone Asset Manager',
    errorPulling: 'Error pulling assets.',
    errorDeleting: 'Error deleting assets.',
    errorDecoupling: 'Error decoupling assets.',
    invalidFileFormat: 'The file [formats] “[extensions]” is not supported.',
    errorMaxSize: 'File size needs to be less than 2GB',
    duplicate: 'Duplicate',
    delete: 'Delete',
    tooltipNewHTML: 'Create a new HTML asset',
    invalidHTMLAsset: 'Invalid HTML Asset Format. File not Uploaded.'
};

export const variables = {
    title: 'Variables'
};

export const timeline = {
    compModal: {
        continue: 'Continue',
        label: 'Enter the name for your new composition',
        title: 'New Composition'
    },
    render: 'Preview',
    addLayer: 'Add Layer',
    fitError: 'Cut does not fit within other cuts',
    startOverlapError: 'New startFrame would overlap with another cut',
    startEndError: 'New startFrame is >= endFrame',
    endOverlapError: 'New endFrame would overlap with another cut',
    endStartError: 'New endFrame is <= startFrame',
    tooltipAddOverlay: 'Add Overlay to Cut',
    tooltipEditBase: 'Upload new base video',
    tooltipAddCut: 'Add New Cut',
    tooltipPlayBase: 'Play Base Video',
    baseVideo: 'Base Video',
    confirmCutDelete: 'Are you sure you want to delete the selected cut?',
    confirmLayerDelete: 'Are you sure you want to delete the selected layer?',
    confirmLayersDelete: 'Are you sure you want to delete the selected layers?',
    confirmOverlayDelete: 'Are you sure you want to delete the selected overlay?',
    tooltipAddAudioOverlay: 'Add Audio Overlay',
    createNewPrompt: 'New Composition',
    createNewFromAsset: 'New Composition from [type]',
    createNewHeader: 'Click the button below or drop a video asset here to begin.',
    newComposition: 'New Composition',
    wrongCompFileTypeDrop: 'Compositions can only be created with an Image or Video.',
    errorCreatingComp: 'Error creating a new composition, please try again.',
    tooltipJumpToKeyframe: 'Jump to Keyframe',
    tooltipDeleteKeyframe: 'Delete Keyframe',
    interpolationTitle: 'Keyframe Interpolation',
    interpolationBody: `In the event the timing of this layer changes due to its source length or being anchored to a dynamic length layer, how should the keyframe position change?`,
    interpolationContext: {
        PROPORTIONAL:
            'Keyframe position will move proportionally, relative to the new layer length.',
        NEAREST_END:
            'Keyframes will be anchored to the start or end of the layer, whichever they are closest to.',
        STATIC: 'Keyframe position will remain static, and not change.'
    },
    copyLayer: 'Layer Copied',
    copyLayers: 'Layers Copied',
    pasteLayer: 'Layer Pasted',
    pasteLayers: 'Layers Pasted'
};
export const preview = {
    previewNotAvailable: 'Preview not available',
    noVariable: 'No variable selected',
    noVariablePreview: 'No preview set',
    noAssetSel: 'No asset selected',
    noTagsSet: 'No tags set',
    noAssetFound: 'No asset found matching tags',
    errorLoadingSource: 'Error loading layer source',
    loadingSource: 'Loading layer source',
    recursiveComp: 'Recursive Composition Detected.\nComposition not added.'
};

export const fields = {
    global: {
        dateCreated: 'Date Created',
        dimensions: 'Dimensions',
        scale: 'Scale',
        anchorPoint: 'Anchor Point',
        position: 'Position',
        video: 'Video',
        audio: 'Audio',
        text: 'Text',
        image: 'Image',
        image_sequence: 'Image Sequence',
        html: 'HTML',
        template: 'Template',
        solid: 'Solid',
        rate: 'Frame Rate',
        lut: 'LUT filter',
        backgroundColor: 'Background Color',
        color: 'Color',
        frames: 'Frames',
        version: 'Version',
        coords: 'Coordinates',
        name: 'Name',
        width: 'Width',
        height: 'Height',
        resize: 'Resize',
        fit: 'Fill Mode',
        anchor: 'Anchor',
        variable: 'Variable',
        delay: 'Delay',
        asset: 'Asset',
        type: 'Type',
        enabled: 'Enabled',
        x: 'X',
        y: 'Y',
        top: 'Top',
        bottom: 'Bottom',
        right: 'Right',
        left: 'Left',
        static: 'Static',
        required: 'Required',
        optional: 'Optional',
        default: 'Default',
        options: 'Options',
        id: 'ID',
        duplicate: 'Duplicate',
        delete: 'Delete',
        duration: 'Duration',
        ease: 'Ease',
        cancel: 'Cancel',
        bucket: 'Bucket',
        check: 'Check',
        use: 'Use',
        create: 'Create',
        edit: 'Edit',
        general: 'General',
        advanced: 'Advanced',
        preview: 'Preview',
        settings: 'Settings',
        new: 'New',
        deletePrompt: 'Are you sure you want to delete "[name]"? This action cannot be undone.',
        blockingJob: 'Blocking Job Running - Editor disabled until complete.',
        tooltipHotKeys: 'Keyboard Shortcuts',
        varExistPrompt: 'Do you want to override any existing variables?',
        rename: 'Rename'
    },
    base: {
        uploadPrompt: 'Add a base video to begin',
        uploading: 'Uploading',
        processing: 'Processing',
        preparingUpload: 'Preparing Base Video Upload.',
        preparingError: 'Error getting signed URL for base video upload.',
        uploadingBase: 'Uploading Base Video.',
        uploadingError: 'Error Uploading Base Video.',
        processingError: 'Error processing base video.',
        uploadComplete: 'Base video upload complete.',
        processBase: 'Processing Base Video. Job ID: [jobId]',
        processJobCreateError: 'Error creating processBaseVideo job',
        processJobError: 'Error Processing Base Video.',
        processComplete: 'Base video processing complete.'
    },
    story: {
        uploadPrompt: 'Create Project'
    },
    asset: {
        dragPrompt: 'Drag Asset Here',
        tooltipFilterAsset: 'Filter assets to the selected Asset',
        details: 'Asset Details',
        newDetails: 'New Asset Details',
        addTag: 'Add Tag',
        tags: 'Tags',
        copyId: 'Copy Asset ID',
        copyUrl: 'Copy Asset URL',
        fieldMismatchError: 'Asset Type mismatch. Field only accepts "[accepts]" assets.',
        tooltipClear: 'Clear the selected Asset',
        tooltipFilter: 'Filter assets to match Asset type',
        tooltipAssetDetails: 'Asset Details',
        tooltipCreateComposition: 'Create a new composition using this asset as the base',
        downloadAsset: 'Download',
        downloadOriginalAsset: 'Download Original',
        downloadAssetPreview: 'Download Preview',
        decoupleAsset: 'Make Global',
        coupleAsset: 'Made Local',
        cancelUpload: 'Cancel Upload',
        replaceFile: 'Replace File',
        downloadJSON: 'Download JSON'
    },
    codeEditor: {
        tab: 'Tab Mode',
        split: 'Split Screen'
    },
    customVar: {
        nameEmptyError: 'Custom variable name cannot be empty',
        placeholderKey: 'key',
        placeholderValue: 'value',
        tooltipAdd: 'Add a new custom variable, to be passed into the [type] on this cut.',
        valueEmptyError: 'Custom variable value cannot be empty',
        tooltipKey:
            'The Object key which this custom variable will be passed in as, within the IMPOSIUM_INVENTORY Object inside of your [type].',
        tooltipValue: 'The value of this custom variable.',
        add: 'Add Custom Var',
        title: 'Custom Vars'
    },
    keyValue: {
        nameEmptyError: 'Name cannot be empty',
        valueEmptyError: 'Value cannot be empty',
        add: 'Add'
    },
    cutConfig: {
        assetId: 'From Asset',
        effects: 'Effects',
        offset: 'Offset',
        audio: 'Clip Audio',
        name: 'Name',
        start: 'In Frame',
        end: 'Out Frame',
        swap: 'Clip Swap',
        overlayMethod: 'Overlay Method',
        title: 'Cut Config',
        btnPreview: 'Preview Cut',
        btnDeleteCut: 'Delete Cut',
        btnAddOverlay: 'Add Overlay',
        tooltipRenderCut: 'Render Cut Preview',
        tooltipName: 'Display name of the Cut.',
        useCutLength: 'Use Cut Length',
        tooltipCutLength:
            'By default, clips swaps are the length of whichever clip is pulled in. If selected, this will trim the clip swap to the length of the cut.',
        tooltipStartFrame: 'Beginning of the Cut, relative to the base video.',
        tooltipEndFrame:
            'End of the cut, relative to the base video. This is the first frame the cut is NOT visible.',
        tooltipClipSwap:
            'Whether or not this cut will dynamically pull in a clip from the Assets, via tags.',
        tooltipOverlay:
            'Method in which Imposium will composite overlay frames onto base video frames.',
        tooltipAudio: 'Audio configuration for this clip swap',
        tooltipOffset: 'Timing Offset (seconds) for the start of the clip swap.',
        tooltipAssetId: 'Pull in a specific clip or composition'
    },
    imgStoryConfig: {
        title: 'Image Configuration',
        baseConfig: 'Base Configuration',
        layers: 'Layers',
        bgColor: 'Background',
        tooltipBgColor: 'Initial background color of the image, before any overlays are applied.',
        tooltipWidth: 'Base width of the image, before any encoding settings are applied.',
        tooltipHeight: 'Base height of the image, before any encoding settings are applied.'
    },
    effectConfig: {
        blendMode: 'Blend Mode',
        mask: 'Mask',
        opacity: 'Opacity',
        saturation: 'Saturation',
        hue: 'Hue',
        brightness: 'Brightness',
        motionBlur: 'Motion Blur',
        showHide: 'Show / Hide',
        animOn: 'Animation On',
        animOff: 'Animation Off',
        tooltipMask: 'The Asset to use as a mask for this Overlay.'
    },
    overlayConfig: {
        ttsService: 'Service',
        ttsVoice: 'Voice',
        ttsModel: 'Model',
        tooltipTts: 'Use a text variable & text-to-speech API to generate the source of this layer',
        tts: 'Text to Speech',
        fontNotFound: 'Font not found',
        lut: 'LUT Filter',
        templateWidth: 'Template Width',
        templateHeight: 'Template Height',
        templateBgColor: 'Background Color',
        positionType: 'Position Type',
        assetType: 'Asset Type',
        sourceType: 'Source Type',
        script: 'Media Script',
        source: 'Source',
        position: 'Position',
        effects: 'Effects',
        tooltipName: 'Display name of the Overlay',
        tooltipType: 'Type of Overlay',
        engine: 'HTML Engine',
        templateDownload: 'Sample',
        downloadButton: 'Download',
        volumeMultiplier: 'Volume Multiplier',
        fadeIn: 'Volume Fade In',
        fadeOut: 'Volume Fade Out',
        tooltipFadeIn: 'Time to fade the volume in, in seconds.',
        tooltipFadeOut: 'Time to fade the volume out, in seconds.',
        offset: 'Start Offset',
        btnDeleteOverlay: 'Delete Overlay',
        btnDuplicateOverlay: 'Duplicate Overlay',
        tooltipEngine: 'Which HTML engine to use when rendering a template.',
        tooltipEnabled: 'Whether or not the Overlay is enabled during the render.',
        tooltipSourceType: 'Where the content of the overlay is coming from.',
        tooltipMediaScript: 'MediaScript that will output the overlay source.',
        tooltipTempHeight: 'Height (pixels) at which to render the template.',
        tooltipTempWidth: 'Width (pixels) at which to render the template.',
        tooltipVariable: 'Which variable to pull in for the overlay source.',
        tooltipAssetType: 'Which type of asset to pull in via Tags.',
        tooltipAsset: 'Which Asset to use as the source of the overlay.',
        tooltipVersion: 'Which version of the processed variable to use.',
        tooltipPositionType:
            'How the overlay will be positioned when it is composited onto the video frame',
        tooltipRectSequence:
            'Sequence of X,Y coordinate pairs, comma separated, one pair per frame.',
        tooltipMTSequence: 'Mocha Corner Pin or After Effects Corner Pin data.',
        tooltipMTQuadTransform: 'Transform Corner pin data into comma separated string.',
        tooltipMTQuadScale: 'Scale transform data by scalar value in input',
        tooltipDownloadTemplate: 'Download a default template for this overlay.',
        tooltipVolumeMultiplier: 'Multiply the default audio volume by this value.',
        tooltipOffset: 'Delay the start of this overlay (in seconds)',
        tooltipBgColor:
            'The CSS background color of the <body> element will be set to this value. Rendering is generally faster if a solid background (vs transparent) is used.',
        tooltipLut: 'LUT asset used to transform the color of the base or clip swap of this cut'
    },
    layerConfig: {
        generateTTS: 'Generate Text-to-speech Layer',
        tooltipOffsetFrames: 'How many frames of this layer are trimmed off of the beginning',
        offsetFrames: 'Offset Frames',
        animation: 'Animation',
        layer: 'Layer',
        templateAnimated: 'Animated',
        templateGPU: 'Requires GPU',
        tooltipTemplateAnimated:
            'Whether or not to render this template as an image sequence (checked) or a static image (unchecked).',
        tooltipTemplateGPU:
            'Whether or not to render this template on a pod which has access to a GPU.',
        options: 'Options',
        info: 'Info',
        id: 'Layer ID',
        lut: 'LUT Filter',
        templateWidth: 'Template Width',
        templateHeight: 'Template Height',
        templateBgColor: 'Background Color',
        positionType: 'Position Type',
        assetType: 'Asset Type',
        sourceType: 'Source Type',
        optional: 'Optional',
        script: 'Media Script',
        source: 'Source',
        position: 'Position',
        layout: 'Layout',
        effects: 'Effects',
        tooltipName: 'Display name of the Overlay',
        tooltipType: 'Type of Overlay',
        engine: 'HTML Engine',
        templateDownload: 'Sample',
        downloadButton: 'Download',
        volumeMultiplier: 'Volume Multiplier',
        offset: 'Start Offset',
        btnDeleteOverlay: 'Delete Overlay',
        btnDuplicateOverlay: 'Duplicate Overlay',
        tooltipEngine: 'Which HTML engine to use when rendering a template.',
        tooltipEnabled: 'Whether or not the Overlay is enabled during the render.',
        tooltipSourceType: 'Where the content of the overlay is coming from.',
        tooltipMediaScript: 'MediaScript that will output the overlay source.',
        tooltipTempHeight: 'Height (pixels) at which to render the template.',
        tooltipTempWidth: 'Width (pixels) at which to render the template.',
        tooltipVariable: 'Which variable to pull in for the overlay source.',
        tooltipAssetType: 'Which type of asset to pull in via Tags.',
        tooltipAsset: 'Which Asset to use as the source of the overlay.',
        tooltipVersion: 'Which version of the processed variable to use.',
        tooltipPositionType:
            'How the overlay will be positioned when it is composited onto the video frame',
        tooltipRectSequence:
            'Sequence of X,Y coordinate pairs, comma separated, one pair per frame.',
        tooltipMTSequence: 'Mocha Corner Pin or After Effects Corner Pin data.',
        tooltipMTQuadTransform: 'Transform Corner pin data into comma separated string.',
        tooltipMTQuadScale: 'Scale transform data by scalar value in input',
        tooltipDownloadTemplate: 'Download a default template for this overlay.',
        tooltipVolumeMultiplier: 'Multiply the default audio volume by this value.',
        tooltipOffset: 'Delay the start of this overlay (in seconds)',
        tooltipBgColor:
            'The CSS background color of the <body> element will be set to this value. Rendering is generally faster if a solid background (vs transparent) is used.',
        tooltipLut: 'LUT asset used to transform the color of the base or clip swap of this cut',
        assetId: 'From Asset',
        audio: 'Clip Audio',
        name: 'Name',
        start: 'Start Frame',
        end: 'End Frame',
        swap: 'Clip Swap',
        overlayMethod: 'Overlay Method',
        title: 'Cut Config',
        btnPreview: 'Preview Cut',
        btnDeleteCut: 'Delete Cut',
        btnAddOverlay: 'Add Overlay',
        tooltipRenderCut: 'Render Cut Preview',
        useCutLength: 'Use Cut Length',
        tooltipCutLength:
            'By default, clips swaps are the length of whichever clip is pulled in. If selected, this will trim the clip swap to the length of the cut.',
        tooltipStartFrame: 'Beginning of the Cut, relative to the base video.',
        tooltipEndFrame:
            'End of the cut, relative to the base video. This is the first frame the cut is NOT visible.',
        tooltipClipSwap:
            'Whether or not this cut will dynamically pull in a clip from the Assets, via tags.',
        tooltipOverlay:
            'Method in which Imposium will composite overlay frames onto base video frames.',
        tooltipAudio: 'Audio configuration for this clip swap',
        tooltipAssetId: 'Pull in a specific clip or composition',
        anchorEnd: 'End Anchor',
        anchorStart: 'Start Anchor',
        filterAssetName: 'Asset Name',
        missingFrames: 'Missing Frames',
        newVarProxy: 'New Variable Proxy',
        varProxies: 'Variable Proxies',
        fitToComp: 'Fit To Comp'
    },
    textConfig: {
        highlight: 'Highlight Color',
        name: 'Name',
        width: 'Width',
        height: 'Height',
        rotation: 'Rotation',
        color: 'Color',
        x: 'X',
        y: 'Y',
        wrap: 'Wrap',
        fit: 'Fit',
        fillMode: 'Fill Mode',
        hAlign: 'H. Align',
        vAlign: 'V. Align',
        backgroundColor: 'Background Color',
        strokeColor: 'Outline Color',
        strokeWeight: 'Outline Weight',
        layers: 'Layers',
        layerText: 'Text',
        fontType: 'Font Type',
        layerImage: 'Image',
        fontSize: 'Font Size',
        lineHeight: 'Line Height',
        font: 'Font',
        letterSpacing: 'Letter Spacing',
        wordSpacing: 'Word Spacing',
        italic: 'Italic',
        caps: 'Caps',
        tooltipName: 'Display name of this composition.',
        tooltipBackgroundColor: 'Background color of this composition. Can also be transparent.',
        tooltipWidth: 'Width of the composition, in pixels',
        tooltipHeight: 'Height of the composition, in pixels',
        tooltipAddLayer: 'Add a new layer to the composition',
        tooltipLayerRotation: 'Rotation angle of the layer, in degrees',
        tooltipLayerX: 'Layer X position.',
        tooltipLayerY: 'Layer Y position',
        tooltipLayerWidth: 'Layer width, in pixels.',
        tooltipLayerHeight: 'Layer height, in pixels',
        textOptions: 'Text Options',
        textLayerContent: 'Content',
        tooltipTextLayerContent:
            'The copy rendered in this layer. Wrap variables like so: {{variable_id}}.'
    },
    encoding: {
        urlWarning: 'Input must be a valid URL address',
        nameWarning: 'Output Name/ID must be unique',
        tooltipCreateNewPreset: 'Create the new video encoding preset',
        skipVideo: 'Skip Video Output',
        tooltipSkipVideo: `Don't output any videos on this project. Instead, only output image and/or audio.`,
        newImageOutput: 'New Image Output',
        newAudioOutput: 'New Audio Output',
        addNewOutput: 'Add Output',
        addVideoOutput: 'Add Video Output:',
        addImageOutput: 'Add Image Output:',
        addAudioOutput: 'Add Audio Output:',
        newScratch: 'New from Scratch',
        newPoster: 'Add Poster',
        newAdditional: 'Add Image',
        newAudio: 'Add Audio',
        tooltipNewPoster: 'Add a poster image.',
        tooltipNewAdditional: 'Add an additional image output.',
        tooltipNewAudio: 'Add an additional audio output.',
        tooltipNewScratch: 'Add a blank encoding setting config.',
        tooltipNewPreset: 'Add an encoding setting from a list of defaults.',
        format: 'Format',
        resize: 'Resize Type',
        video: 'Video Settings',
        videoBitrate: 'Video Bitrate (kB)',
        videoMaxrate: 'Video Maxrate',
        videoBufsize: 'Video Bufsize',
        videoPreset: 'Video Preset',
        videoProfile: 'Video Profile',
        videoQuality: 'Video Quality',
        audioBitrate: 'Audio Bitrate (kB)',
        audioSampelRate: 'Sample Rate (kHz)',
        audioChannels: 'Audio Channels',
        audioQuality: 'Audio Quality',
        audio: 'Audio Settings',
        playlist: 'Generate HLS',
        quality: 'Image Quality',
        cut: 'Cut',
        frame: 'Frame Number',
        script: 'Script',
        infoAddPoster: `The poster image is a special image output, which will be used as the poster frame of the video in the Imposium video player. It's always returned as "poster" via the Imposium API.`,
        infoAddAdditional:
            'Additional Images can be output for each experience. They will be returned via the API using whichever ID is specified for them here.',
        infoAddAudio:
            'Additional Audios can be output for each experience. They will be returned via the API using whichever ID is specified for them here.',
        infoNewImage:
            'Each image output will be created every time an experience is rendered. They will be returned via the API using whichever ID is specified for them here.',
        errorIdExists: `Image Output IDs must be unique. "[ID]" already exists`,
        errorNoId: 'Image Output ID can not be empty',
        errorIdPoster: `The ID "poster" is reserved for Poster Images. Please enter another ID.`,
        tooltipScript: 'Post-Processing script to be applied to this output frame',
        tooltipFrameNumber:
            'Which frame to pull from the selected Cut. Relative to the beginning of the cut',
        tooltipCut: 'Which cut to pull the dynamic frame from.',
        tooltipQuality: 'Fidelity of the output images. Higher quality = larger file size.',
        tooltipNewImageOutput: 'Add a new output image to this project.',
        tooltipName:
            'Name of encoding setting. Will be included in experience output via the Imposium API.',
        tooltipID:
            'Unique ID of encoding setting. Will be included in experience output via the Imposium API.',
        tooltipWidth: 'Width of output. In pixels.',
        tooltipImageFormat: 'File format of the output image.',
        tooltipAudioFormat: 'File format of the output audio.',
        tooltipHeight: 'Height of output. In pixels.',
        tooltipResize:
            'Whether to crop or letterbox the video if the output aspect ratio is different than the input aspect ratio.',
        tooltipVideo: 'Video encoding settings. FFMPEG flags.',
        tooltipAudio: 'Audio encoding settings. FFMPEG flags.',
        tooltipAudioChannels: 'Select Mono or Stereo',
        tooltipFormat: 'Output video format.',
        tooltipHLS:
            'Whether or not to create a HLS playlyst (m3u8) from the other output resolutions. Only works if there is at least one mp4 resolution in the encoding settings.',
        tooltipVideoBitrate:
            'Determines the size and quality of video files: the higher the bitrate, the better the quality and the larger the file size because file size = bitrate (kilobits per second) x duration.',
        tooltipAudioBitrate:
            'Determines the size and quality of audio files: the higher the bitrate, the better the quality and the larger the file size because file size = bitrate (kilobits per second) x duration.',
        tooltipAudioSampleRate:
            'Determines sampling rate or sampling frequency taken from a continuous signal to make a discrete or digital signal.',
        tooltipVideoPreset:
            'Presets optimize the trade-off between encoding speed (encoded frames per second) and compression efficiency (quality per bit in the bitstream).',
        tooltipVideoProfile: 'Video Profile',
        tooltipVideoQuality: 'Video Quality',
        tooltipAudioQuality: 'Audio Quality',
        tooltipAddVideoOutput: 'Add a new video output format.',
        tooltipAddImageOutput: 'Add a new image output format.',
        tooltipAddAudioOutput: 'Add a new audio output format.'
    },
    moderation: {
        rejected: 'Relocate Rejected',
        initialStatus: 'Initial Status',
        onApprove: 'Approve Action',
        onReject: 'Reject Action',
        onError: 'Error Action'
    },
    project: {
        comp: 'Composition ID',
        story: 'Story ID',
        storyName: 'Project',
        compName: 'Composition',
        uploads: 'Uploads Bucket',
        disable: 'Disable Project',
        variableAssets: 'Variable Asset Cache',
        rendered: 'Rendered Bucket',
        checkBucket: 'Checking whether Bucket: "[bucket]" Already exists.',
        checkError: 'Error checking s3 bucket',
        bucketCreatePrompt:
            'Bucket does not alreay exist. To Create it, select a region, and click "Create".',
        bucketCheckPrompt: 'Enter a new bucket name and click "Check" to confirm if it exists.',
        creatingBucket: 'Creating Bucket: "[bucket]"',
        creatingError: 'Error creating s3 bucket.',
        creatingSuccess: 'Bucket: "[bucket]" Created successfully.',
        bucketUsePrompt: 'Bucket exists. Press "Use" to use it.',
        disablePrompt: 'Are you sure you want to disable this project?',
        varAssetsPrompt:
            'Are you sure you want to enable the variable asset cache in this project?',
        gaId: 'GA Tracking ID',
        discard: 'Discard UGC',
        awaitApproval: 'Await Approval',
        lockApproval: 'Lock Approval',
        tooltipName: 'Display name of the project.',
        tooltipStoryId:
            'Unique ID of the project. Used to access the project via the API and Imposium JS-SDK.',
        tooltipCompId:
            'Unique ID of the selected composition. Used to render that specific composition via the API and Imposium JS-SDK.',
        tooltipUploads: 'AWS S3 bucket where project assets will be stored.',
        tooltipRenders: 'AWS S3 bucket where videos will be stored.',
        tooltipGA: 'Google Analytics ID used to track playback metrics.',
        tooltipDiscard: 'Deletes UGC inventory after the video has been rendered.',
        tooltipS3Check: 'Check to see if the bucket already exists on AWS S3.',
        tooltipS3Use: 'Use this bucket.',
        tooltipS3Create: 'Create a new bucket on AWS S3.',
        tooltipRelocate:
            'Move rejected videos to their previous S3 location and invalidate the cache.',
        tooltipInitial: 'The default status of newly created videos.',
        tooltipApprove: 'Action to take when a video is placed in the "Approved" bucket.',
        tooltipError: 'Action to take when there is a fatal error during the video render.',
        tooltipRejected: 'Action to take when a video is placed in the "Rejected" bucket.',
        tooltipAwaitApproval:
            'Do not return an experience to a user until it has been approved in the moderation tool',
        tooltipLockApproval: 'Lock Approval',
        tooltipDisable: 'Prevents any rendering for this project',
        tooltipVarAssets: 'Enable/Disable Variable Asset Cache',
        tooltipCacheClear: 'Clear Variable Asset Cache',
        discardInfo:
            'If selected, all UGC (images, pictures, text, videos, etc) will be deleted from our database as soon as the video has finished rendering. Note: this will prevent you from viewing any of the raw variable values in the Experiences Manager.',
        confirmInventoryDelete: 'Would you to delete all variables associated with this callback?'
    },
    advanced: {
        postProcessing: 'Processing Script',
        audio: 'Audio Script',
        filter: 'Data Filter Script',
        process: 'Limit Concurrency',
        concurrency: 'Concurrency',
        parallel: 'Parallel Mode',
        debug: 'Debug',
        reRender: 'Re-Render Cuts',
        reRenderNotification: 'Re-Rendering Cuts - Editing disabled until complete.',
        reRenderError: 'Error Re-Rendering Cuts.',
        reRenderComplete: 'Re-Rendering Cuts complete - Editing enabled.',
        tooltipProcessing: 'Post Processing MediaScript',
        tooltipAudio: 'Audio MediaScript',
        tooltipDataFilter: 'Data Filter MediaScript.',
        tooltipLimitConcurrency: 'Limit the number of frame processes running at once.',
        tooltipConcurrencyLimit: 'Maximum amount of frame processes to run at once.',
        tooltipParallelMode: 'How to render the cuts.',
        tooltipReRender:
            'Re-Render all of the cuts. Use this if encoding settings have changed, or a new base video has been uploaded.'
    },
    confirm: {
        videoTitle: 'Video Output',
        imageTitle: 'Image Output',
        audioTitle: 'Audio Output'
    },
    image: {
        poster: 'Poster Frame',
        snapshot: 'Snapshot Frame',
        social: 'Social Frame',
        socialScript: 'Social Script',
        frame: 'Frame',
        tooltipFrame:
            'Frame number to use. Use -{X} for frames from the end. For example: -10 would use the tenth frame from the end of the video.',
        tooltipWidth: 'Width of the image, in pixels',
        tooltipHeight: 'Height of the image, in pixels',
        tooltipEnabled: 'Whether or not to export this frame',
        tooltipScript: 'MediaScript to use for creating this frame',
        playIcon: 'Add Play Icon',
        playIconInfo:
            'Renders a play icon on top of your image output. Useful for when the image is used in an email activation.'
    },
    tags: {
        emptyError: 'Tag value cannot be empty',
        add: 'From Tags',
        value: 'Value',
        tooltipTags: 'Tags used to pull in base asset.',
        tooltipAdd: 'Add new tag.',
        tooltipRequired:
            'Whether or not the asset MUST have this tag before it can be used. If no asset is found, the base video will be used.',
        static: 'Static',
        tooltipStatic: 'The text to be passed in for this tag.',
        tooltipVariable: 'The variable to pull the value for this tag from.',
        tooltipType:
            'The type of tags. Static tags use pre-defined text for their value. Variable tags use the value of a Variable, set at render time.',
        tooltipFilterStatic: 'Filter Assets using ONLY static tags.',
        tooltipFilterVariable: 'Filter Assets using static AND variable tags.'
    },
    variable: {
        returnViaAPI: 'Return via API',
        uploadingDefault: 'Uploading Default Variable',
        tooltipAdd: 'Add new Variable',
        idPrompt:
            'Variable IDs are used across the Imposium Platform, API, and SDKs to set and access user data. It is recommended to use human-readable unique IDs, such as "user_email" or "profile_image" when creating Variable IDs. Variable IDs MUST be unique, alphanumeric, cannot contain spaces, and cannot be changed once they are created.',
        tooltipName: 'Display name of the Variable.',
        tooltipIDNew:
            'Unique ID of this variable. MUST BE UNIQUE. Used as key in key+value pair when passing in inventory. Can not be edited once created.',
        tooltipIDExisting: 'Unique ID of this variable. Can not be edited once created.',
        tooltipType: 'Type of Variable.',
        tooltipDefault:
            'The default value of the variable. The default will be used any time an experience is created and the variable is not set',
        tooltipPreview:
            'The preview value of the variable. This will be used when running a cut, and will pre-populate the variable fields when generating a video in project previewer.',
        tooltipOptional:
            'If a variable is optional, an error will not be thrown if a batch is created and there is no column matching that variable',
        tooltipReturn:
            'If set, any time an experience is retrieved via the API or SDK, the value of this variable will be in the payload.',
        tooltipAddOption: 'Add Option',
        maxDuration: 'Max Duration',
        addVersion: 'Versions',
        autoModerate: 'Auto Moderation',
        tooltipAutoModerate: `If selected, UGC passed into this variable will be automatically moderated for inappropriate content using Google's Cloud Video Intelligence API.`,
        tooltipResizeType:
            'How the UGC will be positioned when it is resized to the specified dimensions. Crop will fill the space, cropping off any excess, while letterbox will fit the UGC into the space.',
        tooltipVideoVersion:
            'Video UGC can be pre-processed into "versions" so it can be easily used and manipulated with pre-set dimensions',
        tooltipMaxDuration: 'Trim the uploaded UGC to this duration (in seconds)',
        tooltipOptions: 'Possible options for a List Variable.',
        tooltipNewVar:
            'Create the new variable. All fields except for ID will be editable once you confirm this information.',
        uploadSuccessful: 'Default Variable added successfully.',
        uploadError: 'Error uploading default variable.',
        uniqIdError: 'A Variable already exists with the ID: [id]. Variable IDs MUST be unique',
        emptyIdError: 'Variable ID cannot be empty',
        tooltipEdit: 'Edit integration variable',
        tooltipLock: 'Lock variable',
        errorReserved:
            'The variable ID "[id]" is reserved for system variables. Please enter another ID.'
    },
    api: {
        title: 'API Callback',
        url: 'Callback URL',
        type: 'Request Type',
        script: 'Setup Script',
        headers: 'Headers',
        addHeader: 'Add Header',
        params: 'Parameters',
        addParam: 'Add Parameter',
        tooltipURL: 'The URL the Imposium backend will hit when the action is triggered',
        tooltipType:
            'If POST or GET is selected, all parameters will be sent as multi-part form data. If JSON is selected, all parameters will be sent in a JSON body.',
        tooltipSetup: 'MediaScript to run before callback is sent',
        tooltipParam: 'Data sent in the request',
        tooltipAddParam: 'Add a new parameter. Key + Value pair.',
        tooltipHeaders: 'Custom headers set on the request',
        tooltipAddHeader: 'Add a new header. Key + Value pair.',
        batchVals: 'Batch',
        projectVals: 'Project',
        outputVals: 'Output',
        varVals: 'Variables',
        headerlabel: 'Header Name',
        headerValue: 'Header Value',
        paramName: 'Parameter Name',
        paramValue: 'Parameter Value',
        paramTitle: 'Request Parameters',
        headerTitle: 'Request Headers'
    },
    settings: {
        selectTemplate: 'Select Template',
        blankTemplate: 'Blank Template',
        blankTempDesc: 'Start from scratch, and select either video, image or audio output.',
        loadingTemplate: 'Creating Project',
        travelerTemplate: 'Traveler Rewards',
        travelerDesc:
            'Traveler Rewards is a template with 3 video clips swap, with a starting and end card.',
        sampleCSV: 'Download Sample CSV',
        newComp: 'New Composition',
        confirm: 'Confirm Output Settings',
        project: 'Project',
        advanced: 'Advanced',
        moderation: 'Moderation',
        image: 'Image Output',
        video: 'Video Output',
        audio: 'Audio Output',
        integration: 'Integration',
        player: 'Player'
    },
    metadata: {
        header: 'Metadata',
        title: 'Title',
        tooltipTitle: 'Title of the experience',
        description: 'Description',
        tooltipDescription: 'Description of the Experience',
        twitter: 'Twitter Handle',
        tooltipTwitter: 'Twitter Handle, including the @.',
        facebook: 'Facebook App ID',
        tooltipFacebook: 'Facebook App ID',
        locale: 'Locale',
        tooltipLocale: 'Value for og:locale'
    },
    integration: {
        access: 'API Credentials',
        name: 'Name',
        tooltipName: 'Display name for this set of credentials.',
        key: 'Access Key',
        tooltipKey: 'Access key used for the Imposium REST API and JavaScript SDK.',
        secret: 'Secret',
        tooltipSecret: 'Secret key used to create HMAC tokens for the Imposium REST API.',
        details: 'Access Details',
        documentation: 'Documentation',
        rest: 'REST API',
        restLink: 'https://docs.imposium.com',
        js: 'JavaScript SDK',
        jsLink: 'https://github.com/NickelMedia/Imposium-JS-SDK',
        jsSdkLink: 'https://docs.imposium.com/js-sdk/#/',
        docParagraph:
            'Use the links below for more information on integrating Imposium into your application.',
        accessParagraph:
            'These credentials are used accross all Imposium APIs and SDKs to create and access your videos.',
        newCreds: 'New Credentials',
        tooltipNewCreds: 'Add a name and hit + to create a new set of credentials.',
        tooltipAddCreds: 'Create New Credentials',
        errorCreatingCreds: ' Error creating credentials, contact your Imposium admin for support.',
        errorPullingComps:
            ' Error getting composition IDs, contact your Imposium admin for support.',
        errorPullingAEs:
            ' Error getting After Effect IDs, contact your Imposium admin for support.',
        errorGettingCreds: ' Error getting credentials, contact your Imposium admin for support.',
        noCreds: 'No Credentials found, create some below',
        noName: 'The Name field can not be empty.',
        tooltipRemoveCreds: 'Delete Credentials',
        confirmCreds:
            'Are you sure you want to delete these credentials? This will break ANY application using these credentials, and cannot be undone.',
        codeSampleTitle: 'Code Samples & Testing',
        codeSampleDescription:
            'You can use the code samples and links below to get started building and testing your integrations with the Imposium Platform. Please make sure you have generated an access key above.',
        codeSampleJSBrowser: 'Code Sample (JavaScript)',
        demoPage: 'Demo Page (Web)',
        codeSampleDLButton: 'download',
        sdkArchiveSuffix: ' - JS SDK Integration.zip',
        selectCreds: 'Select Credentials',
        credentialAlert:
            'Credentials Not Found - If you would like to set up new credentials, please enter a name for your credentials below:'
    },
    globalShortcutsHeading: 'Global',
    layerShortcutHeading: 'Layers',
    timelineShortcutsHeading: 'Timeline',
    previewShortcutsHeading: 'Preview',
    VideolayerShortcutHeading: 'Video Layers',
    mac: {
        globalShortcuts: {
            'CMD + S': 'Save',
            'CMD + Z': 'Undo',
            'CMD + Shift + Z': 'Redo',
            'V': 'Show / Hide Variables'
        },
        previewShortcuts: {
            'Scroll Wheel': 'Zoom In/Out',
            'Hold Space + Click & Drag': 'Pan'
        },
        timelineShortcuts: {
            'CMD + Left': 'Previous frame',
            'CMD + Shift + Left': 'Back 10 frames',
            'CMD + Right': 'Next frame',
            'CMD + Shift + Right': 'Skip 10 frames',
            'Page Down': 'Previous frame',
            'Shift + Page Down': 'Back 10 frames',
            'Page Up': 'Next frame',
            'Shift + Page Up': 'Skip 10 frames'
        },
        layerShortcuts: {
            'Hold Option': 'Toggle adjust layer scale/size',
            'Option + [': 'Set IN point',
            'Option + ]': 'Set OUT point',
            '[': 'Move layer IN point',
            ']': 'Move layer OUT point',
            'I': 'Jump to IN point',
            'O': 'Jump to OUT point',
            'HOLD A': 'Show layer anchors',
            'HOLD P': 'Show copy layer property',
            'CMD + D': 'Duplicate selected layer',
            'CMD + Up': 'Previous Layer',
            'CMD + Down': 'Next Layer',
            'Delete': 'Delete selected layer',
            'Hold Shift': 'Toggle lock aspect ratio'
        },
        videoLayers: {
            'Shift + Drag In Point': 'Set the layer offset'
        }
    },
    win: {
        globalShortcuts: {
            'CTRL + S': 'Save',
            'CTRL + Z': 'Undo',
            'CTRL + Y': 'Redo',
            'V': 'Show / Hide Variables'
        },
        previewShortcuts: {
            'Scroll Wheel': 'Zoom In/Out',
            'Shift + Click & Drag': 'Pan'
        },
        timelineShortcuts: {
            'ALT + Left': 'Previous frame',
            'ALT + Shift + Left': 'Back 10 frames',
            'ALT + Right': 'Next frame',
            'ALT + Shift + Right': 'Skip 10 frames',
            'Page Down': 'Previous frame',
            'Shift + Page Down': 'Back 10 frames',
            'Page Up': 'Next frame',
            'Shift + Page Up': 'Skip 10 frames'
        },
        layerShortcuts: {
            'Hold Option': 'Toggle adjust layer scale/size',
            'ALT + [': 'Set IN point',
            'ALT + ]': 'Set OUT point',
            '[': 'Move layer IN point',
            ']': 'Move layer OUT point',
            'I': 'Jump to IN point',
            'O': 'Jump to OUT point',
            'HOLD A': 'Show layer anchors',
            'CTRL + D': 'Duplicate selected layer',
            'ALT + Up': 'Previous Layer',
            'ALT + Down': 'Next Layer',
            'Delete': 'Delete selected layer',
            'Hold Shift': 'Toggle lock aspect ratio'
        },
        videoLayers: {
            'Shift + Drag In Point': 'Set the layer offset'
        }
    },
    compositions: {
        copyLayers: 'Copy Layers',
        deleteLayers: 'Delete Layers',
        duplicateLayers: 'Duplicate Layers',
        createCompFromLayers: 'Create Composition From Layers',
        copyLayer: 'Copy Layer',
        deleteLayer: 'Delete Layer',
        duplicateLayer: 'Duplicate Layer',
        createCompFromLayer: 'Create Composition From Layer',
        create: 'Create Composition',
        renderable: 'Renderable Composition',
        settings: 'Composition Settings',
        closeTab: 'Close tab',
        baseVideo: 'Base Video',
        layerDuration: 'Fit To Layer Duration',
        layerDurationInfo:
            'If selected, the composition duration will automatically change to the end of the last layer.',
        swapDuration: 'Duration',
        errorTooManyFrames: `Compositions cannot exceed [duration] seconds duration at the current tier.`,
        swapDurationInfo:
            'If set to static, the layer duration will use the start/end frames set in the editor. If set to dynamic, the layer duration will change to the duration of the asset pulled in via the tags.',
        noComposition: 'You need to create your first composition!',
        saveSettings: 'Save Settings',
        outputSettings: 'Output Settings',
        outputSettingsInfo:
            'If you want to exclude any of the project output settings when this composition is rendered - deselect the checkbox beside the setting name.'
    },
    publish: {
        accessKey: 'Access Key',
        tooltipAccessKey: 'API access key to use for this video distribution.',
        publishTitle: 'Publish & Deliver',

        // publish
        publishStepTitle: 'STEP 1: Publish your Project',
        publishStepDesc:
            "Your project needs to be published before your changes can be seen. If you haven't made any changes since the last time you published, you can skip to the next step.",

        // distribute
        distributeStepTitle: 'STEP 2: How do you want your users to view their video?',
        distributeStepDesc:
            'Select which Composition you want to deliver, access credentials you want to use, and distribution channel.',
        compositionError: 'Please Select Composition',

        // webpage
        btnWebsite: 'Webpage',
        webpageTitle: 'STEP 3: Webpage',
        webPageHosted: 'Webpage hosted on Imposium',
        webpageDesc:
            'You can share a demo page that is hosted on Imposium or you can download a sample webpage you can customize and use to generate videos. Please ensure you include a column for every variable on your project.',
        sampleWebpage: 'Download sample webpage',

        // email
        btnEmail: 'Email',
        exporting: 'Exporting CSV... (please wait)',
        emailTitle: 'STEP 3: Upload a CSV file with your user information in it.',
        emailDesc:
            'You can download a sample CSV with fields to populate as a starting point. Or you can upload one that already has the data in it.',
        downloadSampleCsvLink: 'Download Sample CSV',
        uploadCsv: 'Upload Your CSV UserList',
        generatingLink: 'STEP 4: Generating embed links',
        downloadLink: 'STEP 4: Download Your Userlist CSV file',
        btnDownload: 'Download CSV',
        generatingLinkDesc:
            'Please wait while we generate the link for your users. This may take few minutes.',
        missingColumns: 'Error: missing required columns',
        createBatchFailed: 'Error: Failed to create batch',
        downloadError: 'Error downloading sample CSV.',
        embeddedCodeCopyError: 'Error copying embed code.',
        creatingLinks: 'Creating User Links... (please wait)',
        importingData: 'Importing Data... (please wait)',
        downloadDesc:
            'Your CSV data file is ready to download with an embed code you can import into your email platform to send out.',

        // export
        exportBatchOfVideo: 'Export',
        renderBatchFailed: 'Error: Failed to render batch',

        // hubspot
        btnHubspot: 'Hubspot',
        hubspotTitle: 'STEP 3: Copy Hubspot code snippet',
        hubspotDesc:
            'Copy the provided embed code into HubSpot. Replace the placeholder variable values with HubSpot template strings, to splice in your user data.',
        saveChanges: 'Save Changes',
        embeddedCode: 'Embed Url: ',

        // api
        btnAPI: 'API integration',

        // global
        btnPublish: 'Publish',
        btnSkip: 'Skip',
        btnBack: 'Back',
        btnFinished: 'Done',
        noCompErrorTitle: 'No Compositions Found',
        noCompErrorDesc: `You can't publish and deliver your project until you have created your first composition.`
    },
    queue: {
        queueConfirm:
            'Are you sure you want to assign this project to the [QUEUE_NAME] queue? This could affect processing times',
        queueTitle: 'Queue'
    }
};

export const viewer = {
    previewTitle: 'Timeline Preview',
    cutRunning: 'Rendering Cut',
    time: 'Time Elapsed:',
    runningCut: 'Running Cut - Job ID: [jobId]',
    runningCutError: 'Error Running Cut',
    btnRenderCut: 'Re-Render',
    btnVideo: 'Video',
    btnLog: 'Job Log'
};

export const errors = {
    pane: 'Whoops, Something went wrong.',
    feedback: 'Tell us what happened: '
};

export const storyErrors = {
    saveStoryWithError: 'Project saved with errors',
    attemptPublishWithError: `Cannot Publish project due to errors. Please fix all errors and try again.`,
    saveStoryError: 'Error saving project - changes were not saved',
    deleteStoryError: 'Error deleting project.',
    assetCacheClearError: `Error clearing Asset Cache`,
    createStoryError: 'Error creating project - project not added to database',
    clipSwapError: 'Clip swap enabled with no tag added',
    overlayAssetError: 'Source Type Asset - Missing Asset',
    overlayAssetTagError: 'Source Type Asset Pool - Missing Asset Tag',
    overlayVariableError: 'Source Type Variable - Missing Variable',
    overlayScriptError: 'Source Type Script - Missing Script',
    overlayRectError: 'Position Type Rectangle - Missing Coordinate(s)',
    overlayCornerPinError: 'Position Type Corner Pin - Missing Coordinate(s)',
    overlayRectSeqError: 'Position Type Rectangle Sequence - Missing Coordinates(s)',
    overlayCornerPinSeqError: 'Position Type Corner Pin Sequence - Missing Coordinate(s)',
    overlayMaskError: 'Mask Asset Not Found',
    foundErrors: 'Project Errors Found',
    errorDetails: 'Error Details',
    cutErrorTitle: 'Cut Errors',
    overlayErrorTitle: 'Overlay Errors',
    audioErrorTitle: 'Audio Errors',
    assetNotFound: 'Asset Not Found',
    showLayer: 'Show Layer'
};

export const viewerErrors = {
    cutTitle: 'Cut Errors',
    overlayTitle: 'Overlay Errors',
    audioOverlayTitle: 'Audio Overlay Errors'
};

export const codeTemplate = {
    configuration: 'Preview Settings',
    config: {
        label: {
            type: 'Animated',
            fps: 'FPS',
            frames: 'Frames',
            frame: 'Frame',
            height: 'Height',
            width: 'Width',
            background: 'Background Color'
        },
        tooltips: {
            type: 'Whether or not to render this template as an image sequence (checked) or a static image (unchecked).',
            fps: 'Frames Per Sec',
            frames: 'Total Frames',
            frame: 'Start Frame',
            height: 'Template Height',
            width: 'Template Width',
            background: 'Template Background Color'
        }
    }
};

export const copyAsset = {
    copied: 'Copied to clipboard!',
    error: 'Error copying to clipboard.'
};

export const layerOptions = {
    downloadSample: 'Download Sample',
    createHTML: 'Create HTML Asset'
};

export const video = {
    tooltipSetIn: 'Set In Frame (i)',
    tooltipSetOut: 'Set Out Frame (o)',
    tooltipJumpIn: 'Jump to In Frame (b)',
    tooltipJumpOut: 'Jump to Out Frame (n)',
    tooltipTrim: 'Trim',
    tooltipCancelTrim: 'Cancel Trim as New Asset',
    cancel: 'Cancel',
    newTrim: 'Trim as New'
};

export const dataEditor = {
    generateNoVarsError:
        'No variables found on project. You must have at least 1 variable on the project to generate sample data. Please add some variables and try again.',
    datasetTooLong:
        'The selected CSV is over the limit of [max] rows by [overBy] rows, not including the header row. It will be trimmed to [max] rows. Would you like to continue?',
    publishError: 'Errors found in dataset - would you like to proceed?',
    generatePrompt:
        '[rows] will be created using the data from the variable list, and will overwrite the existing dataset. Do you wish to proceed?',
    noSwapError:
        'Could not generate asset list for selected dataset. No asset swaps found in composition.',
    cont: 'Continue',
    newColTitle: 'New Column',
    newColPrompt: 'Enter an name for your new column. Column names must be unique.',
    renamePrompt: 'Enter a new name for your dataset',
    newPrompt: 'Enter the name for your new dataset',
    duplicatePrompt: 'Enter the name for your copy of [name]',
    btnContinue: 'Continue',
    btnUpdateName: 'Save',
    import: 'Import Data',
    export: 'Export Data',
    publish: 'Publish',
    getAssets: 'Get Asset List',
    btnRender: 'Render',
    btnRenderTooltip: 'Render a video using the data in this row',
    btnImgVidPreviewTooltip: 'Click to preview the [type] with url',
    btnPreview: 'Preview',
    btnPreviewTooltip:
        'Update the preview values on your variables to preview the row in the project editor',
    confirmDelete: 'Are you sure you want to delete [name]? This action cannot be undone!',
    generateSampleData: 'Generate Data',
    confirmSampleData:
        'Are you sure you want to generate sample data for this dataset? This will overwrite any data you currently have.',
    unmatchedTagsMsg: 'No matching Assets with Tag(s), [tags].',
    clipSwapErrorMsg: 'Asset is too short for following layer(s): [layers].',
    emptyColumnsMsg: 'Column(s), [columns], cannot be empty.',
    checkForErrors: 'Check for Errors',
    errorCheckIndicator: 'Checking dataset for errors',
    assetListIndicator: 'Generating asset list',
    noDatasetDefault: 'Select Dataset',
    noDatasetsFound: 'No datasets were found'
};
